<template>
  <div class="homePage" v-loading="isLoading">
    <template>
      <div class="navbar flex col-center row-between drag h-80">
        <div class="shop-name flex col-center">
          <img src="@/assets/images/logo.png" alt="" class="mr-5">{{shopName || '电竞蜂网咖'}}
        </div>
        <!--<button class="no-drag" @click="changeTheme">切换主题</button>-->
        <div class="tabs flex col-center fz-16 no-drag">
          <div class="item cursor" :class="{'active': tabkey == item.key && item.icon.indexOf('/') < 0}"
               @click="changeTab(item.key)"
               v-for="(item, index) in tabList" :key="index">
            <template v-if="item.icon.indexOf('/') >= 0">
              <img :src="item.activeIcon" v-if="tabkey == item.key" :style="item.size"/>
              <img :src="item.icon" :style="item.size" v-else/>
            </template>
            <i class="iconfont mr-5" :class="item.icon" v-else></i>
            <span>{{ item.title }}</span>
          </div>
        </div>
        <i class="el-icon-close icon-close cursor" @click="closeWin"/>
      </div>

      <shop v-if="tabkey == 1"/>
      <guess v-if="tabkey == 2"/>
      <wallet v-if="tabkey == 3"/>
      <signIn v-if="tabkey == 4"/>
      <europeanCup v-if="tabkey == 6"/>
    </template>

    <!--包时-->
    <packetTime v-model="packetTimeShow"/>
    <ad-model v-model="showAd">
      <img src="@/assets/images/modal/recharge.png" style="width: 100%;height: 100%"/>
    </ad-model>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import shop from "@/views/shop/index.vue";
import wallet from "@/views/wallet/index.vue";
import packetTime from "@/components/packetTime/index.vue";
import signIn from "@/views/signIn";
import guess from '@/views/guess'
import europeanCup from '@/views/europeanCup'
import adModel from "@/components/adModel/index.vue";
import {call} from "@/common/utils";

export default {
  name: 'navBar',
  components: {
    packetTime,
    shop,
    wallet,
    signIn,
    guess,
    europeanCup,
    adModel
  },
  data() {
    return {
      ipc: null,
      isLoading: false,
      tabList: [],
      tabkey: 3,
      packetTimeShow: false,
      showAd: false
    }
  },
  computed: {
    ...mapGetters(['shop', 'shopId', 'shopName', 'secret', 'themeName', 'memberId'])
  },
  methods: {
    changeTheme() {
      this.$store.dispatch('setTheme', this.themeName == 'green_theme' ? 'red_theme': 'green_theme')
    },
    changeTab(key) {
      let tab = this.tabList.find(item => item.key == key)
      if (tab.type == 'call') {
        call(true)
        return
      } else if (tab.type == 'packageTimeModel') {
        this.packetTimeShow = true
        return
      }
      this.tabkey = key
    },
    initData() {
      let tabList = [
        {title: '呼叫', icon: 'icon-Group', type: 'call'},
        {title: '超市', key: 1, icon: 'icon-gouwu1',type: 'tab'},
        {title: '娱乐竞猜', key: 2,  icon: 'icon-youxi',  type: 'tab'},
        /*{
          title: '',
          key: 6,
          icon: require('@/assets/images/europeanCup/nav.png'),
          activeIcon: require('@/assets/images/europeanCup/nav-active.png'),
          size: {width: '96px', height: '32px'},
          type: 'tab'
        },*/
        {title: '签到', key: 4, icon: 'icon-qiandao1', type: 'tab'},
        {title: '钱包', key: 3, icon: 'icon-qianbao2', type: 'tab'},
      ]
      this.loadMember()
      if (this.shopId < 9000 || this.shopId > 9100) {
        tabList.splice(  1, 0, {title: '包时', key: 5, icon: 'icon-shijian1', type: 'packageTimeModel'})
      }
      this.tabList = tabList
      this.changeTab(1)
    },
    loadMember() {
      let query = this.$route.query
      if (!query.secret) {
        this.$message.error('非法登录')
        return
      }
      this.isLoading = true
      this.$store.dispatch('load', query.secret).finally(() => {
        this.isLoading = false
      })
    },
    closeWin() {
      window.ipc.send('window-shop-min')
    },
  },
  created() {
    this.initData()
  },
  mounted() {
    window.ipc.send('changePage')
    window.ipc.on('changePage', (event, data) => {
      this.initData()
      this.changeTab(data)
    })
    window.ipc.on('call-network', (event, data) => {
      call()
    })
  },
}
</script>
<style lang="scss">
.homePage {
  height: $-window-height;
  background: #fff;
  .navbar {
    font-size: 24px;
    color: #fff;
    padding: 0 16px 0 28px;
    @include header_bg(310deg, 0%, 100%);
    position: relative;
    .tabs {
      padding-right: 40px;
      .item {
        padding: 5px 20px;
        margin-right: 16px;
        &.active {
          @include font_color();
          background: rgba(255, 255, 255, 0.8);
          border-radius: 4px;
        }
      }
    }
    .icon-close {
      position: absolute;
      right: 24px;
    }
  }
}
</style>
