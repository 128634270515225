<template>
  <div class="home">
    <div class="item" v-for="item in tabs" :key="item.id">
      <div class="home-item" :style="{background: item.status == 0 ? item.bgColor : item.bgColorFull}" @click="changeTab(item.id)">
        <i class="iconfont" :class="item.icon" v-if="item.icon.indexOf('/') < 0"></i>
        <img :src="item.icon" v-else  style="width: 100%;height: 100%;" />
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Tab',
  data () {
    return {
      tabs: [
        {
          id: 1,
          bgColor: 'linear-gradient(315deg, #8fd6ff 0%, #66aaff 74%)',
          icon: 'icon-goodsfill',
          name: '超市',
          status: 0
        }, {
          id: 2,
          bgColor: 'linear-gradient(315deg, #fc8771 0%, #fc5254 74%)',
          icon: 'icon-moneybagfill',
          name: '余额',
          status: 0
        },
        /*{
          id: 7,
          bgColor: '',
          icon: require('@/assets/images/icon-ozb.png'),
          name: '',
          status: 0
        },*/
        {
          id: 6,
          bgColor: 'linear-gradient(to right, #FF512F 0%, #DD2476  51%, #FF512F 100%)',
          icon: 'icon-qiandao',
          name: '签到',
          status: 0
        },
        {
          id: 3,
          bgColor: 'linear-gradient(315deg, #aba2fe 0%, #7e6ef9 74%)',
          icon: 'icon-jiezhang',
          name: '结账',
          status: 0
        }, {
          id: 4,
          bgColor: 'linear-gradient(315deg, #fa6f75 0%, #fc5d6c 74%)',
          bgColorFull: 'linear-gradient(to right, rgb(12, 235, 235), rgb(32, 227, 178), rgb(41, 255, 198))',
          icon: 'icon-10',
          name: '呼叫',
          status: 0
        },
        // {
        //   id: 5,
        //   bgColor: 'linear-gradient(290deg, #ff7f26 0%,#ff755d 100%)',
        //   icon: 'icon-pinglun1',
        //   name: '留言',
        //   status: 0
        // }
      ]
    }
  },
  methods: {
    changeTab (type) {
      switch (type) {
        case 1:
          window.ipc.send('change-tab', 1)
          break
        case 2:
          window.ipc.send('change-tab', 3)
          break
        case 3:
          window.ipc.send('window-check-show')
          break
        case 4:
          var timestamp = new Date().getTime()
          let oldTime = sessionStorage.oldTime
          if (oldTime) {
            let times = Math.abs(timestamp - oldTime) >= (0.5 * 60 * 1000)
            if (!times) {
              return
            }
          }
          this.tabs.find(item => item.id == type).status = 1
          this.tabs.find(item => item.id == type).name = '已呼叫'
          setTimeout(() => {
            this.tabs.find(item => item.id == type).status = 0
            this.tabs.find(item => item.id == type).name = '呼叫'
          }, 1000)
          window.ipc.send('call-network')
          sessionStorage.oldTime = new Date().getTime()
          break
        // case 5:
        //   window.ipc.send('window-comment', window.location.protocol + '//' + window.location.host + '/#/comment')
        //   break
        case 6:
          window.ipc.send('change-tab', 4)
          break
        case 7:
          window.ipc.send('change-tab', 6)
          break
        default:
          break
      }
    },
  },
  created () {
    let query = this.$route.query
    if (query.secret) {
      this.$store.dispatch('load',query.secret)
    }
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 10px;
  justify-content: space-around;
  box-sizing: border-box;
  .item {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .home-item {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    &:active {
      transform: scale(0.9);
    }
    .iconfont {
      font-size: 26px;
    }

  }
}
</style>
