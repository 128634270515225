<template>
  <c-model v-model="show" width="1008px" title="消费记录" class="consume-model" @close="show = false">
    <span slot="footer"></span>
    <!--<div class="lists-right flex col-center row-end mt-10">
      <div class="mr-10">
        <el-date-picker
          v-model="dateArr"
          type="daterange"
          size="small"
          range-separator="至"
          start-placeholder="开始日期"
          value-format="yyyy-MM-dd"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div>
        <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
      </div>
    </div>-->
    <el-table ref="table" :data="list" v-loading="loading" size="small" class="table mt-20" height="500" :span-method="arraySpanMethod">
      <el-table-column prop="title" label="类型" align="center" width="100px"/>
      <el-table-column prop="startTime" label="开始时间" align="center" width="160"/>
      <el-table-column prop="endTime" label="结束时间" align="center" width="160"/>
      <el-table-column prop="money" label="金额" align="center" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.money < 0" class="color-red">{{scope.row.money}}</span>
          <span v-else>{{scope.row.money}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="shopName" label="门店" align="center" show-overflow-tooltip/>
      <el-table-column prop="remark" label="备注" align="center" show-overflow-tooltip/>
    </el-table>
  </c-model>
</template>
<script>
import {balanceBill} from "@/api/wallet";
import {mapGetters} from "vuex";

export default {
  name: "index",
  props: {
    value: {type: Boolean, default: false}
  },
  data() {
    return {
      loading: false,
      list: [],
      dateArr: [],
      query: {
        pageNum: 1,
        pageSize: 20
      },
      finish: false,
      total: 0
    }
  },
  computed: {
    ...mapGetters(['memberNumber']),
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    getList() {
      this.query.memberNumber = this.memberNumber
      const query = {
        ...this.query,
        // ...this.formatParamsDayRange(this.dateArr)
      }
      if (this.loading || this.finish) return
      this.loading = true
      balanceBill(query).then(res => {
        res.rows.map(item => {
          item.money = item.presentedMoney + item.availMoney
          item.remark = item.deviceNo ? item.deviceNo : item.remark
        })
        this.list = this.list.concat(res.rows)
        this.finish = this.list.length >= res.total
        if (!this.finish) {
          this.query.pageNum++;
        } else {
          this.list.push({type: -1, title: '没有更多了'})
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleSearch() {
      this.query.pageNum = 1
      this.list = []
      this.finish = false
      this.getList()
    },
    scroll() {
      this.$nextTick(() => {
        let dom = this.$refs.table.bodyWrapper
        dom.addEventListener('scroll', () => {
          // 滚动距离
          let scrollTop = dom.scrollTop
          // 变量windowHeight是可视区的高度
          let windowHeight = dom.clientHeight || dom.clientHeight
          // 变量scrollHeight是滚动条的总高度
          let scrollHeight = dom.scrollHeight || dom.scrollHeight
          if (scrollHeight - (scrollTop + windowHeight) <= 50) {
            this.getList()
          }
        })
      })
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === this.list.length - 1 && row.type == -1) {
        return [1, 6];
      }
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.scroll()
        this.getList()
      }
    }
  }
}
</script>

<style  lang="scss">
.consume-model {
  padding-bottom: 20px;
}
</style>
