import moment from 'moment'
import {dateFormat} from "@/common/utils";
export default {
  data() {
    return {
      // 时间跨度一个月
      dateRangeOneMonthOptions: {
        onPick: ({ minDate, maxDate }) => {
          this.pickerOneRangeMinDate = minDate
          if (maxDate) {
            this.pickerOneRangeMinDate = ''
          }
        },
        disabledDate: time => {
          if (this.pickerOneRangeMinDate) {
            let allowMaxTime = moment(this.pickerOneRangeMinDate).add(1, 'month').format('x')
            let allowMinTime = moment(this.pickerOneRangeMinDate).subtract(1, 'month').format('x')
            let currentTime = time.getTime()
            return currentTime > allowMaxTime || currentTime < allowMinTime
          } else {
            return false
          }
        }
      },
      // 时间跨度三个月
      dateRangeThreeMonthOptions: {
        onPick: ({ minDate, maxDate }) => {
          this.pickerThreeRangeMinDate = minDate
          if (maxDate) {
            this.pickerThreeRangeMinDate = ''
          }
        },
        disabledDate: time => {
          if (this.pickerThreeRangeMinDate) {
            let allowMaxTime = moment(this.pickerThreeRangeMinDate).add(3, 'month').format('x')
            let allowMinTime = moment(this.pickerThreeRangeMinDate).subtract(3, 'month').format('x')
            let currentTime = time.getTime()
            return currentTime > allowMaxTime || currentTime < allowMinTime
          } else {
            return false
          }
        }
      },
      // 时间限制不能跨月
      nowMonthPickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate = minDate.getTime()
          if (maxDate) {
            this.choiceDate = ''
          }
        },
        disabledDate: time => {
          if (this.choiceDate) {
            const startDay = (new Date(this.choiceDate).getDate() - 1) * 24 * 3600 * 1000
            const endDay = (new Date(
                  new Date(this.choiceDate).getFullYear(),
                  new Date(this.choiceDate).getMonth() + 1,
                  0
                ).getDate() - new Date(this.choiceDate).getDate()) * 24 * 3600 * 1000
            const minTime = this.choiceDate - startDay
            const maxTime = this.choiceDate + endDay
            return time.getTime() < minTime || time.getTime() > maxTime
          }
          return false
        }
      }
    }
  },
  methods: {
    formatParamsDayRange(t1, t2) {
      if (!t1 && !t2) {
        return {}
      }
      let startTime = '', endTime = ''
      if (Array.isArray(t1)) {
        startTime = t1[0]
        endTime = t1[1]
      } else {
        startTime = t1
        endTime = t2
      }
      if (!startTime || !endTime) return {}
      return {
        startTime: dateFormat(startTime, 'yyyy-MM-dd 00:00:00'),
        endTime: dateFormat(endTime, 'yyyy-MM-dd 23:59:59')
      }
    }
  }
}
